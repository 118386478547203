import React, { Component } from "react";
import ExternalTool from "./ExternalTool";
export default class ExternalToolResource extends Component {
  constructor(props) {
    super(props);
    this.resource = props.resource;
  }

  launchUrl() {
    let launchUrlElement = this.findElementByTagNameWithoutPrefix(
      this.props.doc,
      "launch_url"
    );
    if (launchUrlElement) {
      return launchUrlElement.textContent;
    } else {
      launchUrlElement = this.findElementByTagNameWithoutPrefix(
        this.resource,
        "launch_url"
      );
      if (launchUrlElement) {
        return launchUrlElement.textContent;
      }
    }

    return "";
  }

  findElementByTagNameWithoutPrefix(node, tagName) {
    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i];
      if (
        child.tagName &&
        child.tagName.toLowerCase().endsWith(tagName.toLowerCase())
      ) {
        return child;
      }

      const element = this.findElementByTagNameWithoutPrefix(child, tagName);
      if (element) {
        return element;
      }
    }
  }

  render() {
    const launchUrl = this.launchUrl();
    return <ExternalTool launchUrl={launchUrl} />;
  }
}
