import React, { Component } from "react";
import oauth from "oauth-sign";

import iFrameResize from "iframe-resizer/js/iframeResizer";

export default class ExternalTool extends Component {
  componentDidMount() {
    document.getElementById("ltiForm").submit();
    iFrameResize({ log: false, checkOrigin: false }, "#ltiFrame");
    this.enablePrintButtons();
  }

  enablePrintButtons() {
    const launchUrl = document.getElementById("ltiForm").action;
    if (launchUrl.includes("LtiAmalgamator")) {
      document.getElementById("printContentButton").style.display = "flex";
      document.getElementById("printAssessmentButton").style.display = "flex";
    } else if (launchUrl.toLowerCase().includes("assessment")) {
      document.getElementById("printAssessmentButton").style.display = "flex";
    } else {
      document.getElementById("printContentButton").style.display = "flex";
    }
  }

  ltiParams() {
    const action = this.props.launchUrl.split("?")[0];
    const queryParams = this.props.launchUrl.split("?")[1];
    const method = "POST";
    const timestamp = Math.round(Date.now() / 1000);
    const params = {
      // LTI Required Parameters
      lti_message_type: "basic-lti-launch-request",
      lti_version: "LTI-1p0",
      resource_link_id: "resourceLinkId",
      // OAuth 1.0a Required Parameters
      oauth_consumer_key: process.env.REACT_APP_LTI_KEY,
      oauth_nonce: btoa(timestamp),
      oauth_signature_method: "HMAC-SHA1",
      oauth_timestamp: timestamp,
      oauth_version: "1.0",
      // Strongmind Custom Parameters
      user_id: 1,
      context_title: this.props.contextTitle || "Missing Context Title",
      resource_link_title:
        this.props.resourceLinkTitle || "Missing Resource Link Title",
      lis_person_name_family: "FamilyName",
      lis_person_name_given: "GivenName",
      lis_outcome_service_url: window.location.href,
      context_id: "contextId",
      roles: "Instructor"
    };
    if (queryParams) {
      queryParams.split("&").forEach(param => {
        const [key, value] = param.split("=");
        params[key] = value;
      });
    }

    params.oauth_signature = oauth.hmacsign(
      method,
      action,
      params,
      process.env.REACT_APP_LTI_SECRET
    );
    return params;
  }

  render() {
    const params = this.ltiParams();
    let launchUrl = this.props.launchUrl;
    return (
      <div>
        <form
          id={"ltiForm"}
          action={launchUrl}
          method="POST"
          target={"ltiFrame"}
        >
          {Object.entries(params).map(([key, value]) => {
            return <input key={key} type="hidden" name={key} value={value} />;
          })}
        </form>
        <iframe
          src="about:blank"
          width="100%"
          frameBorder="0"
          name={"ltiFrame"}
          id={"ltiFrame"}
          title="LTI Frame"
        />
      </div>
    );
  }
}
